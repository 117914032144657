import BCard from "components/B/card";
import React, { useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { AccurateService, CommonService, OrderService } from "api/axios";
import { Loading, LocalDialog } from "components";
import { Button, Card, CardBody, Table } from "reactstrap";
import { ChevronLeft } from "react-bootstrap-icons";
import BStatus from "components/B/status";
import BForm from "components/B/form";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { openAlert } from "store/features/alert/AlertSlice";
import { getRouteName, routeMapping } from "routes";
import { formatComma, formatDateOnly, getAccurateCreds } from "helper";
import EditScheduleModal from "./ScheduleEdit";
import ModalCreateDO from "./modalCreateDO";
import BRouteEditForm from "components/B/RouteEditForm";
import { getDeliveredQuantity } from "helper/product";
import ModalAccurateLogin from "./modalAccurateLogin";
import { Icon } from "@iconify/react";
import ModalApproval from "./modalApproval";

type ChipStatusType =
	| "SUBMITTED"
	| "APPROVED"
	| "DECLINED"
	| "FINISHED"
	| "CLOSED"
	| "AWAITING_FOR_PAYMENT";
interface IChipColors {
	SUBMITTED: string;
	APPROVED: string;
	DECLINED: string;
	FINISHED: string;
	CLOSED: string;
	AWAITING_FOR_PAYMENT: string;
}

const chipColors = {
	SUBMITTED: "yellow", // yellow
	APPROVED: "green",
	FINISHED: "green", // green
	DECLINED: "red", // red
	CLOSED: "red",
	AWAITING_FOR_PAYMENT: "purple"
} as IChipColors;

// interface PayloadDO {
// 	scheduleId?: {
// 		number: string;
// 		id: string;
// 	};
// 	date?: Date;
// 	quantity?: number;
// 	sealNumber?: string;
// 	licenseNumber?: number;
// 	driver?: {
// 		id: string;
// 		fullName: string;
// 	};
// 	truck?: {
// 		id: string;
// 		plate_number: string;
// 	};
// }

interface PayloadWO {
	scheduleId?: {
		number: string;
		id: string;
	};
	date?: Date;
	sku?: {
		id: string;
		sku: string;
	};
	mixDesign?: {
		id: string;
		name: string;
	};
	workOrder?: {
		id: string;
		number: string;
	};
	inputWoNumber?: string;
	notes?: string;
	quantity?: number;
	mcPasir?: number;
	mcAbuBatu?: number;
	mcSplit?: number;
	mcScreening?: number;
}

export default function ScheduleDetail() {
	const location = useLocation();
	const permission = useAppSelector((state) => state.user.permission);
	const schedulePermission = useMemo(() => {
		const routeName = getRouteName(location.pathname, routeMapping);

		if (routeName) {
			return permission[routeName];
		}
		return {};
	}, [location, permission]);
	const navigate = useNavigate();
	const params = useParams();
	const id = params?.id;
	const [projectDetails, setProjectDetails] = React.useState<any[]>([]);
	const [summary, setSummary] = React.useState<any[]>([]);
	const [sphNumber, setSphNumber] = React.useState<string>("");
	const [customerName, setCustomerName] = React.useState<string>("");
	const [DOs, setDOs] = React.useState<any[]>([]);
	const [routes, setRoutes] = React.useState<any[]>([]);
	const [loadingButton, setIsLoadingButton] = React.useState(false);
	const [statusSchedule, setStatusSchedule] =
		React.useState<ChipStatusType>();
	const [status, setStatus] = React.useState<string>("");
	const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
	const [isApproveModalOpen, setIsApproveModalOpen] = React.useState(false);
	const [idWOSelected, setIdWOSelected] = React.useState<any>({});
	const [skuSelected, setSkuSelected] = React.useState<any>({});
	const [editModalDate, setEditModalDate] = React.useState<any>();
	const [requestTechnicianModal, setRequestTechnicianModal] =
		React.useState<boolean>();
	const [payloadRouteEdit, setPayloadRouteEdit] = React.useState<any>(null);
	const [editIdSchedule, setEditIdSchedule] = React.useState<string>();
	const [editIdProject, setEditIdProject] = React.useState<
		string | undefined
	>(undefined);
	const [hasDo, setHasDo] = React.useState<any>(false);
	const { bp } = useAppSelector((store) => store.batching);
	const [isCreateDO, setIsCreateDO] = React.useState(false);
	const [isAccurateLoginOpen, setIsAccurateLoginOpen] = React.useState(false);
	const { isAccurateDisabled: accurateStatusDisabled } = useAppSelector(
		(state) => state.setting
	);
	const isAccurateDoDisabled =
		process.env.REACT_APP_IS_ACCURATE_DISABLED === "true" ||
		accurateStatusDisabled;

	const { isLoading, refetch, data } = useQuery<
		{ data: ScheduleResponse },
		ApiError
	>(
		["query-schedule"],
		async () => {
			return await OrderService.getOneSchedule(id ?? "");
		},
		{
			enabled: true,
			onSuccess: (res) => {
				const isCompany = !!res?.data?.Project?.companyName;
				const isCustomer = !!res?.data?.Project?.Customer;
				setStatusSchedule(res.data.status);
				setSphNumber(res?.data.number);
				setCustomerName(
					res?.data?.Project?.Customer?.displayName as string
				);
				setStatus(res?.data?.status);
				setEditModalDate(res?.data?.date);
				setRequestTechnicianModal(res?.data?.withTechnician);
				setEditIdSchedule(res?.data?.id);
				setEditIdProject(res?.data?.Project?.id);
				// setHasDo(res?.data?.DeliveryOrders.length > 0 ? true : false);

				res?.data?.WorkOrders.forEach((wo) => {
					if (wo.DeliveryOrder) {
						setHasDo(true);
					}
				});

				setSkuSelected(
					res?.data?.SaleOrder?.PoProduct?.SKU
				);
				try {
					const DOs = res?.data?.WorkOrders;
					setDOs(DOs);
				} catch (e) {
					//
				}

				try {
					const projectDetails = [
						(!isCompany && !isCustomer) ||
						(isCompany && !isCustomer)
							? {
									type: "noCustomer"
							  }
							: null,
						isCustomer && {
							name: "Tipe Pelanggan",
							type: "customerTypeChip",
							data:
								res?.data?.Project.Customer?.type === "COMPANY"
									? "PERUSAHAAN"
									: res?.data?.Project.Customer?.type
						},
						isCompany && !isCustomer
							? {
									name: "Tipe Pelanggan",
									type: "customerType",
									data: "PERUSAHAAN"
							  }
							: null,
						isCustomer
							? {
									name: "Nama Pelanggan",
									data:
										res?.data?.Project.Customer
											?.displayName ?? "-"
							  }
							: null,
						{
							name: "Nama Proyek",
							data: res?.data?.Project?.name
						},
						{
							name: "PIC Utama",
							type: "pic",
							data: {
								name: res?.data?.Project?.mainPic?.name,
								position: res?.data?.Project?.mainPic?.position,
								phone: res?.data?.Project?.mainPic?.phone,
								email: res?.data?.Project?.mainPic?.email
							}
						},
						res?.data?.Project?.Pics.length > 1 && {
							name: "PICs",
							type: "pics",
							data: {
								pics: res?.data?.Project?.Pics,
								mainPIC: res?.data?.Project?.mainPic
							}
						},
						{
							name: "Alamat Penagihan",
							type: "address",
							data: {
								line1: res?.data?.Project?.BillingAddress
									?.line1,
								lat: res?.data?.Project?.BillingAddress?.lat,
								lon: res?.data?.Project?.BillingAddress?.lon
							}
						},
						{
							name: "Alamat Pengiriman",
							type: "address",
							data: {
								line1: res?.data?.Project?.ShippingAddress
									?.line1,
								lat: res?.data?.Project?.ShippingAddress?.lat,
								lon: res?.data?.Project?.ShippingAddress?.lon
							}
						}
					];
					setProjectDetails(projectDetails);
				} catch (e) {
					//
				}

				try {
					setPayloadWO({
						...payloadWO,
						scheduleId: {
							id: res?.data.id as string,
							number: res?.data.number as string
						}
					});
				} catch (e) {
					//
				}

				try {
					// const totalDeliveryPrice = res?.data?.QuotationRequest?.totalDeliveryPrice;
					const summary = [
						{
							name: "Nama Sales",
							type: "text",
							data: res?.data?.Project?.User?.fullName
						},
						{
							name: "SKU",
							type: "text",
							data: res?.data?.SaleOrder?.PoProduct
								?.SKU?.sku
						},
						{
							name: "SPH",
							type: "text",
							data: res?.data?.QuotationLetter?.number
						},
						{
							name: "PO",
							type: "text",
							data: res?.data?.PurchaseOrder?.customerNumber
						},
						{
							name: "SO",
							type: "text",
							data: res?.data?.PurchaseOrder?.brikNumber
						},
						{
							name: "Produk",
							type: "text",
							data: res?.data?.SaleOrder.Product.name
						},
						{
							name: "Produk SKU",
							type: "text",
							// data: res?.data?.SaleOrder.Product.name
							data:
								res?.data?.SaleOrder?.PoProduct
									?.SKU?.sku || "-"
						},
						{
							name: "Produk SKU Description",
							type: "text",
							// data: res?.data?.SaleOrder.Product.name
							data: res?.data?.SaleOrder?.PoProduct
								?.SKU?.technicalDescription
								? (
										res?.data?.SaleOrder?.PoProduct
											?.SKU
											?.technicalDescription || ""
								  ).split("|")[0] +
								  `| ${
										res?.data?.SaleOrder?.PoProduct
											?.SKU
											?.cementitiousComposition ||
										res?.data?.SaleOrder?.PoProduct
											?.SKU
											?.cementitiousCompositio ||
										"-"
								  }`
								: "-"
						},
						{
							name: "Kuantitas",
							type: "text",
							data: formatComma(`${res?.data?.quantity} M³`)
						},
						{
							name: "Kuantitas Terkirim",
							type: "text",
							data: `${getDeliveredQuantity(
								res?.data?.WorkOrders
							)} M³`
							// data: `${res?.data?.deliveredQuantity
							// 	? formatComma(
							// 		`${res?.data?.deliveredQuantity} M³`
							// 	)
							// 	: "0 M³"
							// 	}`
						},
						// {
						// 	name: "Tol",
						// 	type: "boolean",
						// 	data: res?.data?.QuotationLetter.QuotationRequest?.viaTol,
						// },
						{
							name: "Status Jadwal",
							type: "chips",
							data: [
								res?.data?.status && {
									text:
										res?.data?.status ===
										"AWAITING_FOR_PAYMENT"
											? "AWAITING FOR PAYMENT"
											: res?.data?.status,
									color: chipColors[res?.data?.status],
									textColor: "white"
								}
							]
						},
						{
							name: "Tipe Pembayaran",
							type: "chips",
							data: [
								res?.data?.QuotationLetter.QuotationRequest
									?.paymentType == "CREDIT"
									? {
											text: "Credit",
											color: "green",
											textColor: "white"
									  }
									: {
											text: "Cash",
											color: "blue",
											textColor: "white"
									  }
							]
						},
						{
							name: "Tanggal & Jam Tiba Di Lokasi",
							type: "datetime",
							data: res?.data?.date
						},
						{
							name: "Konsekutif",
							type: "boolean",
							data: res?.data?.consecutive
						},
						{
							name: "Request Teknisi",
							type: "boolean",
							data: res?.data?.withTechnician
						},
						{
							name: "Metode Penuangan",
							type: "text",
							data: res?.data?.pouringMethod
						}
					];
					setSummary(summary);
				} catch (e) {
					//
				}
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	// const { isLoading, refetch, data }
	const { refetch: refetchRoutes } = useQuery<DataResponseRoutes, ApiError>(
		["query-routes"],
		async () => {
			return await CommonService.getRoutesBySchedule(id ?? "");
		},
		{
			enabled: true,
			onSuccess: (res) => {
				try {
					setRoutes(
						(res.data || []).map((p) => {
							return {
								type: "route",
								data: p
							};
						})
					);
				} catch (e) {
					//
				}
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	const patchStatus = useMutation(
		async (status: "APPROVED" | "DECLINED") => {
			return await OrderService.patchSchedule(id as string, status);
		},
		{
			onSuccess: (response) => {
				refetch();
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
			}
		}
	);

	// const [payloadDO, setPayloadDO] = React.useState<PayloadDO>();
	const [payloadWO, setPayloadWO] = React.useState<PayloadWO>();
	const forms: ElementForm[] = React.useMemo(() => {
		return [
			{
				label: "Nomor Jadwal",
				type: "text",
				value: payloadWO?.scheduleId?.number,
				placeholder: "Pilih Schedule",
				disable: true
			},
			{
				label: "SKU",
				type: "text",
				value: skuSelected
					? `${skuSelected?.sku} - ${skuSelected?.description}`
					: "Silahkan Pilih SKU di SO terlebih dahulu",
				style: !skuSelected
					? {
							borderColor: "red",
							color: "red",
							backgroundColor: "#ffe8e8"
					  }
					: undefined,
				disable: true,
				placeholder: "Pilih SKU"
			},
			{
				label: "Nomor WO",
				type: "text",
				value: payloadWO?.inputWoNumber,
				placeholder: "WO/BRIK-LGK/2023/09/00001 (kosongkan jika auto)",
				disable: false,
				onChange: (e: any) => {
					setPayloadWO({
						...payloadWO,
						inputWoNumber: e.target.value
					});
				}
			},
			{
				label: "Tanggal",
				type: "date",
				value: payloadWO?.date,
				onChange: (e: any) =>
					setPayloadWO({
						...payloadWO,
						date: e.target.value
					}),
				placeholder: "Pilih Tanggal",
				min: true //tanggal minimal hari ini
			},
			{
				label: "Notes",
				type: "text",
				value: payloadWO?.notes,
				placeholder: "Tambahkan catatan jika ada",
				disable: false,
				onChange: (e: any) => {
					setPayloadWO({
						...payloadWO,
						notes: e.target.value
					});
				}
			},
			{
				label: "Kuantitas (m³)",
				type: "quantity",
				value: payloadWO?.quantity,
				onChange: (e: any) =>
					setPayloadWO({
						...payloadWO,
						quantity: e.target.value
					}),
				placeholder: "Masukan Kuantitas"
				// maxQuantity: (() => {
				// 	// const total = data?.data.DeliveryOrders.reduce(
				// 	// 	(a, b) => a + b.quantity,
				// 	// 	0
				// 	// );
				// 	const total = data?.data?.WorkOrders?.reduce(
				// 		(a, b) => a + (b?.DeliveryOrder?.quantity || 0),
				// 		0
				// 	);

				// 	if (total) {
				// 		return (data?.data.quantity as number) - total;
				// 	}
				// 	return data?.data.quantity as number;
				// })()
			}
		];
	}, [data, payloadWO]);

	const [localDialog, setLocalDialog] = React.useState({
		isOpen: false,
		onClose: () => setLocalDialog({ ...localDialog, isOpen: false }),
		onOpen: () => setLocalDialog({ ...localDialog, isOpen: true })
	});

	const [routeDialog, setRouteDialog] = React.useState({
		isOpen: false,
		onClose: () => setRouteDialog({ ...routeDialog, isOpen: false }),
		onOpen: () => setRouteDialog({ ...routeDialog, isOpen: true })
	});

	const dispatch = useAppDispatch();

	const createWO = useMutation(
		async (payload: PayloadCreateDO) => {
			return await OrderService.createWorkOrder(payload);
		},
		{
			onSuccess: (response) => {
				refetch();
				localDialog.onClose();
				dispatch(
					openAlert({
						body: "Berhasil Membuat Work Order",
						color: "success"
					})
				);
				setIsLoadingButton(false);
				setPayloadWO({});
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: err.response?.data.message,
						color: "danger"
					})
				);
				setIsLoadingButton(false);
			}
		}
	);

	const onSubmit = () => {
		if (!payloadWO?.quantity || Number(payloadWO?.quantity) <= 0) {
			dispatch(
				openAlert({
					body: "masukan jumlah DO",
					color: "danger"
				})
			);
			return;
		} else if (!skuSelected) {
			dispatch(
				openAlert({
					body: "SKU belum terpilih, mohon pilih SKU terlebih dahulu di SO",
					color: "danger"
				})
			);
			return;
		}
		const payload: PayloadCreateDO = {
			scheduleId: payloadWO?.scheduleId?.id as string,
			skuId: skuSelected?.id,
			inputWoNumber: payloadWO?.inputWoNumber as string,
			date: payloadWO?.date as Date,
			quantity: Number(payloadWO?.quantity) as number,
			notes: payloadWO.notes,
			batchingPlantId: bp?.valueStr
		};
		createWO.mutate(payload);
		setIsLoadingButton(true);
	};

	const handleButton = React.useCallback(
		(status: "APPROVED" | "DECLINED" | "CREATE") => (e?: any) => {
			if (e) e.preventDefault();
			if (status === "CREATE") {
				localDialog.onOpen();
				return;
			}

			if (status === "APPROVED") {
				patchStatus.mutate("APPROVED");
				setIsApproveModalOpen(false);
				return;
			}

			patchStatus.mutate(status);
		},
		[data]
	);

	const sendNotified = useMutation(
		async (idWo: string) => {
			return await OrderService.notifiedLoading(idWo as string);
		},
		{
			onSuccess: (response) => {
				openAlert({
					body: "Berhasil Mengirim Notifikasi",
					color: "success"
				});
			},
			onError: (err: ApiError) => {
				dispatch(
					openAlert({
						body: "Gagal Mengirim Notifikasi",
						color: "danger"
					})
				);
			}
		}
	);

	const handleButtonNotified = React.useCallback(
		(id: string) => (e: any) => {
			e.preventDefault();
			sendNotified.mutate(id);
		},
		[DOs]
	);

	const {
		data: accurateCustomerDetails,
		isLoading: accurateIsLoading,
		isError: accurateIsError
	} = useQuery(
		["query-customer-details"],
		async () => {
			const res = await AccurateService.getAccurateCustomer({
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				id: data?.data.Project?.Customer?.customerAccurateId as number
			});
			return res;
		},
		{
			enabled:
				!!data?.data.Project?.Customer?.customerAccurateId &&
				!isAccurateDoDisabled,
			onError(err: any) {
				console.log(
					err?.response?.data?.error?.data?.error_description,
					"error log"
				);
				let message = "Accurate Error/ Tolong Login Ulang";

				if (err?.response?.data?.error?.data?.error) {
					message = `Accurate Error/ ${err?.response?.data?.error?.status} - ${err?.response?.data?.error?.data?.error}`;
					localStorage.removeItem("accurateCreds");
					// window.location.reload();
				}
				dispatch(
					openAlert({
						body: message,
						color: "danger"
					})
				);
			}
		}
	);

	if (isLoading || patchStatus.isLoading) {
		return <Loading />;
	}

	return (
		<div className="p-4 pt-6">
			{isCreateDO && (
				<ModalCreateDO
					isOpen={isCreateDO}
					setIsOpen={setIsCreateDO}
					refetch={refetch}
					idWOSelected={idWOSelected}
					skuSelected={skuSelected}
					deliveryOrders={DOs}
					customerName={data?.data?.Project?.Customer?.displayName}
					customerAccurateId={
						data?.data?.Project?.Customer?.customerAccurateId
					}
					soAccurateId={
						data?.data?.PurchaseOrder?.saleOrderAccurateId
					}
					addressAccurate={data?.data?.Project?.addressAccurate}
					customerAccurateName={
						(accurateCustomerDetails?.data?.name as string) ?? ""
					}
					defaultCustomer={
						accurateCustomerDetails?.data
							? {
									id: accurateCustomerDetails?.data?.id,
									name: accurateCustomerDetails?.data?.name,
									mobilePhone:
										accurateCustomerDetails?.data
											?.mobilePhone,
									whatsappNo:
										accurateCustomerDetails?.data
											?.whatsappNo,
									customerNo:
										accurateCustomerDetails?.data
											?.customerNo,
									email: accurateCustomerDetails?.data?.email
							  }
							: undefined
					}
				/>
			)}
			<ModalAccurateLogin
				isOpen={isAccurateLoginOpen}
				setIsOpen={setIsAccurateLoginOpen}
				isLoading={accurateIsLoading}
				isError={accurateIsError}
			/>
			{/* <h1>{sphNumber}</h1> */}
			{/* <div className="d-flex flex-row align-items-center mb-2">
				<h1>{customerName}</h1>
			</div> */}
			<div className="d-flex flex-row align-items-center mb-2">
				<div
					className="b-back-btn border mr-3"
					onClick={() => {
						navigate(-1);
					}}
				>
					<ChevronLeft />
				</div>
				<div>
					<h1
						className="mb-0 mr-2 vertical-text"
						style={{ marginBottom: "0em" }}
					>
						{customerName}
					</h1>
					<div
						className="break-line"
						style={{
							maxWidth: "95%",
							marginBottom: "0em",
							marginTop: "0em"
						}}
					/>
					<h1
						className="mb-0 mr-2 vertical-text"
						style={{ marginTop: "0em", fontSize: "1.45em" }}
					>
						{sphNumber}
					</h1>
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						flex: 1,
						alignItems: "center"
					}}
				>
					<BStatus>{status}</BStatus>
					{status === "AWAITING_FOR_PAYMENT" ||
					(status === "SUBMITTED" &&
						schedulePermission &&
						schedulePermission.update) ? (
						<div>
							<Button
								// onClick={handleButton("APPROVED")}
								onClick={() => {
									setIsApproveModalOpen(true);
								}}
								size="sm"
								color="success"
								className="confirm-button approve-button"
							>
								Approve
							</Button>
							<Button
								onClick={handleButton("DECLINED")}
								size="sm"
								color="danger"
								className="confirm-button decline-button ml-1"
							>
								Decline
							</Button>
						</div>
					) : null}
					{status === "APPROVED" &&
					schedulePermission &&
					schedulePermission.create ? (
						<div>
							<Button
								onClick={() => {
									localDialog.onOpen();
								}}
								size="sm"
								color="success"
								className="confirm-button approve-button"
							>
								Buat WO
							</Button>
							<Button
								onClick={() => {
									setIsEditModalOpen(true);
								}}
								size="sm"
								color="warning"
								className="confirm-button decline-button ml-1"
							>
								Ubah
							</Button>
						</div>
					) : null}
				</div>
			</div>
			<Card className="">
				<CardBody>
					<Table responsive hover striped>
						<thead>
							<tr>
								{/* <th className="border-top-0" style={{ textAlign: "left" }}>License Number</th> */}
								<th
									className="border-top-0"
									style={{ textAlign: "left" }}
								>
									No. WO
								</th>
								{/* <th
											className="border-top-0"
											style={{ textAlign: "left" }}
										>
											SKU
										</th> */}
								<th
									className="border-top-0"
									style={{ textAlign: "center" }}
								>
									Kuantitas (m³)
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "center" }}
								>
									Tanggal WO
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "right" }}
								>
									No. DO
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "center" }}
								>
									No. TM
								</th>
								{/* <th
											className="border-top-0"
											style={{ textAlign: "center" }}
										>
											Nama Driver
										</th> */}
								<th
									className="border-top-0"
									style={{ textAlign: "center" }}
								>
									Tanggal DO
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "center" }}
								>
									Status
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "center" }}
								>
									Jam Mulai Loading
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "center" }}
								>
									Jam Selesai Loading
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "center" }}
								>
									Print
								</th>
								<th
									className="border-top-0"
									style={{ textAlign: "center" }}
								>
									Notifikasi
								</th>
							</tr>
						</thead>
						<tbody>
							{[...DOs].map((item, idx) => (
								<tr key={idx}>
									<td
										style={{
											textAlign: "left",
											cursor: "pointer"
										}}
										onClick={() => {
											navigate(
												`/order/work-orders/${item?.id}`
											);
										}}
									>
										{item?.number ?? "-"}
									</td>

									{/* <td style={{ textAlign: "left" }}>
												{item?.SKU.sku}
											</td> */}
									<td style={{ textAlign: "center" }}>
										{item?.quantity
											? formatComma(`${item.quantity} M³`)
											: "0  M³"}
									</td>
									<td style={{ textAlign: "center" }}>
										{formatDateOnly(item?.date)}
									</td>
									<td
										style={{
											textAlign: "right",
											cursor: "pointer"
										}}
										onClick={() => {
											if (item?.DeliveryOrder?.id) {
												navigate(
													`/order/delivery-orders/${item?.DeliveryOrder?.id}`
												);
											}
										}}
									>
										{item?.deliveryOrderId === null &&
										item.status !== "CANCELLED" ? (
											<Button
												style={{
													display: "block",
													marginLeft: "auto"
												}}
												size="sm"
												color="success"
												onClick={(e) => {
													e.stopPropagation();
													console.log(
														isAccurateDoDisabled,
														"isAccurateDoDisabled",
														process.env
															.REACT_APP_IS_ACCURATE_DISABLED
													);

													if (
														getAccurateCreds()
															?.user ||
														isAccurateDoDisabled
													) {
														setIsCreateDO(true);
														setIdWOSelected({
															id: item.id,
															number: item.number,
															date: item.date,
															quantity:
																item.quantity
														});
													} else {
														// window.open(
														// 	`https://account.accurate.id/oauth/authorize?client_id=ba58abb3-239b-4478-b162-eb3388259c2a&response_type=code&redirect_uri=${window.location.origin}/sl/accurate-oauth&scope=item_view item_save sales_invoice_view sales_invoice_save customer_view customer_save purchase_order_save sales_order_view delivery_order_save delivery_order_view branch_view warehouse_view`
														// );
														setIsAccurateLoginOpen(
															true
														);
													}
												}}
											>
												Buat DO
											</Button>
										) : item?.DeliveryOrder?.number ? (
											item?.DeliveryOrder?.number
										) : (
											<BStatus>{item.status}</BStatus>
										)}
									</td>
									<td style={{ textAlign: "center" }}>
										{item?.DeliveryOrder
											? `${item?.DeliveryOrder?.Vehicle?.internalId} / ${item?.DeliveryOrder?.Vehicle?.plateNumber}`
											: "-"}
									</td>
									{/* <td style={{ textAlign: "center" }}>
												{item?.DeliveryOrder
													? item?.DeliveryOrder?.User
															?.fullName
													: "-"}
											</td> */}
									<td style={{ textAlign: "center" }}>
										{item?.DeliveryOrder
											? formatDateOnly(
													item?.DeliveryOrder?.date
											  )
											: "-"}
									</td>
									<td>
										<BStatus data={item?.DeliveryOrder}>
											{item?.DeliveryOrder?.status}
										</BStatus>
									</td>
									<td style={{ textAlign: "center" }}>
										{item?.DeliveryOrder
											?.accurateStartLoadingTime
											? item?.DeliveryOrder
													?.accurateStartLoadingTime
											: "-"}
									</td>
									<td style={{ textAlign: "center" }}>
										{item?.DeliveryOrder
											?.accurateEndLoadingTime
											? item?.DeliveryOrder
													?.accurateEndLoadingTime
											: "-"}
									</td>
									<td style={{ textAlign: "center" }}>
										{item?.DeliveryOrder ? (
											<Button
												onClick={() => {
													const route = routes?.find(
														(p) =>
															p?.batchingPlantId ==
															item?.batchingPlantId
													)?.route;
													const isScreening = ["B" , "D"].includes(item?.SKU?.sku[8]);

													const excludedProductName = item?.SKU?.customerName.split(" ").slice(1);
													const quality = item?.SKU?.mutu;
													const qualitySymbol = quality.split(" - ")[0];
													const qualityValue = quality.split(" - ")[1];
													const strength = item?.SKU?.kekuatanMutu.split(" - ")[0];

													const isQualityFcOrFs = ["F", "S"].includes(qualitySymbol);
													const correctProductName =
													`${qualityValue}-${isQualityFcOrFs ? Number(strength) / 10 : strength} ` + excludedProductName.join(" ");
													const printData = {
														customerName:
															data?.data?.Project
																?.Customer
																?.displayName,
														projectName:
															data?.data?.Project
																?.name,
														projectAddress: item?.DeliveryOrder?.accurateData?.toAddress
														|| data?.data?.Project?.addressAccurate
														|| data?.data?.Project?.ShippingAddress?.line1,
														volume: item
															?.DeliveryOrder
															?.quantity,
														cumulativeVolume:
															item?.DeliveryOrder
																?.accurateData
																?.cumulativeVolume ||
															item?.DeliveryOrder
																?.quantity,
														mobilisationFee:
															item?.DeliveryOrder
																?.accurateData
																?.mobilizationFee ||
															0,
														tollFee:
															item?.DeliveryOrder
																?.accurateData
																?.tollFee || 0,
														distance:
															item?.DeliveryOrder
																?.accurateData
																?.deliveryDistance ||
															(route?.distance
																? route?.distance /
																  1000
																: 0),

														// cumulativeVolume: item?.DeliveryOrder?.quantity, // TODO
														// mobilisationFee: 0, // TODO
														// tollFee: 0, // TODO
														// distance: route?.distance ? (route?.distance / 1000) : 0, // TODO
														concreteName: isScreening ? correctProductName + " - Screening" : correctProductName,
														shortConcreteName:
															data?.data
																?.SaleOrder
																?.Product?.name,
														tmNo: `${item?.DeliveryOrder?.Vehicle?.internalId} / ${item?.DeliveryOrder?.Vehicle?.plateNumber}`,
														doNo: item
															?.DeliveryOrder
															?.number,
														soNo:
															item?.DeliveryOrder
																?.accurateData
																?.salesOrderNumber ||
															data?.data
																?.PurchaseOrder
																?.brikNumber,
														poNo: data?.data?.PurchaseOrder?.customerNumber,
														batchingPlantId:
															item?.batchingPlantId,
														paymentType:
															data?.data
																?.QuotationLetter
																?.QuotationRequest
																?.paymentType,
														id: item?.DeliveryOrder
															?.id,
														createdAt:
															item?.createdAt,
														date: item?.date,
														sealNumber:
															item?.DeliveryOrder
																?.sealNumber,
														woNo: item?.number,
														sku: item?.SKU?.sku,
														loadingTime:
															item?.DeliveryOrder
																?.accurateStartLoadingTime,
														loadingDoneTime:
															item?.DeliveryOrder
																?.accurateEndLoadingTime,
														driverName:
															item?.DeliveryOrder
																?.accurateData
																?.driver ||
															item?.DeliveryOrder
																?.Driver?.name,
														dispatcherName:
															item?.DeliveryOrder
																?.accurateData
																?.dispatcher ||
															"System"
														// TODO: Missing Driver Name!
													};
													console.log(
														printData,
														"printData"
													);
													console.log(
														item?.DeliveryOrder
															?.accurateData,
														"accurateData"
													);
													window.open(
														`brik-dispatch://do/${encodeURIComponent(
															JSON.stringify(
																printData
															)
														)}`,
														"_blank"
													);
												}}
												size="sm"
												color="success"
												className="confirm-button approve-button"
											>
												<Icon
													icon={"mingcute:print-fill"}
												/>
											</Button>
										) : (
											"-"
										)}
									</td>
									<td style={{ textAlign: "center" }}>
										{item?.DeliveryOrder ? (
											<Button
												size="sm"
												color="success"
												className="confirm-button approve-button"
												onClick={handleButtonNotified(
													item.id
												)}
											>
												Loading Sekarang
											</Button>
										) : (
											"-"
										)}
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</CardBody>
			</Card>

			<div className="d-flex flex-column flex-lg-row">
				<div
					className="d-flex flex-column mb-3"
					style={{ flex: "1 1 100%" }}
				>
					<div className="d-flex flex-column flex-lg-row">
						<div
							className="d-flex flex-column mb-3"
							style={{ flex: 0.7 }}
						>
							<BCard
								className="mt-3"
								title="Ringkasan Jadwal"
								data={summary}
							/>
						</div>
						<div className="spacer-v" />
						<div
							className="d-flex flex-column mt-3"
							style={{ flex: 0.3 }}
						>
							<BCard
								title="Rincian Proyek"
								data={projectDetails}
							/>
							<BCard
								className="mt-3"
								title="Route Proyek"
								data={routes}
								titleActions={
									<>
										<Button
											size="sm"
											color="info"
											onClick={() => {
												routeDialog.onOpen();
											}}
											style={{ boxShadow: "none" }}
										>
											Tambah / Edit
										</Button>
									</>
								}
							/>
						</div>
					</div>
				</div>
				{isEditModalOpen ? (
					<EditScheduleModal
						isOpen={isEditModalOpen}
						setIsOpen={setIsEditModalOpen}
						date={editModalDate}
						editIdSchedule={editIdSchedule as string}
						refetch={refetch}
						hasDo={hasDo}
						reqTechnician={requestTechnicianModal as boolean}
					/>
				) : null}
				{isApproveModalOpen ? (
					<ModalApproval
						isOpen={isApproveModalOpen}
						setIsOpen={setIsApproveModalOpen}
						summary={summary}
						projectDetails={projectDetails}
						onApprove={() => handleButton("APPROVED")()}
					/>
				) : null}
				<div className="spacer-v" />
				<div className="d-flex flex-column" style={{ flex: 0.3 }}>
					{/* <BCard title="Rincian Proyek" data={projectDetails} /> */}
					{/* <BCard
						className="mt-3"
						title="Route Proyek"
						data={routes}
						titleActions={
							<>
								<Button
									size="sm"
									color="info"
									onClick={() => {
										routeDialog.onOpen();
									}}
									style={{ boxShadow: "none" }}
								>
									Tambah / Edit
								</Button>
							</>
						}
					/> */}
					{/* <BCard className="mt-3" title="Delivery Orders" data={files} /> */}
				</div>
			</div>
			<LocalDialog
				isOpen={localDialog.isOpen}
				backdrop={"static"}
				header="Buat Work Order"
				onClick={onSubmit}
				loading={loadingButton}
				onClose={() => {
					localDialog.onClose();
					// setPayloadWO({});
					setIsLoadingButton(false);
				}}
			>
				<BForm forms={forms} />
			</LocalDialog>
			<BRouteEditForm
				isOpen={routeDialog.isOpen}
				data={payloadRouteEdit}
				routes={routes}
				projectId={editIdProject}
				onClose={() => {
					routeDialog.onClose();
					refetchRoutes();
				}}
			/>
			{/* <BRoute /> */}
		</div>
	);
}
