import axios, { AxiosError, AxiosResponse } from "axios";
import { getAccurateCreds } from "helper";
import moment from "moment";
axios.defaults.withCredentials = true;

const COMMON_API = process.env.REACT_APP_COMMON_API;
const INVENTORY_API = process.env.REACT_APP_INVENTORY_API;
const ORDER_API = process.env.REACT_APP_ORDER_API;
const PROD_API = process.env.REACT_APP_PRODUCTIVITY_API;
const FINANCE_API = process.env.REACT_APP_FINANCE_API;
const CUSTOMER_API = process.env.REACT_APP_CUSTOMER_API;
const INTEGRATOR_API = process.env.REACT_APP_CUSTOMER_API + "/hub-integrator";
const QC_API = process.env.REACT_APP_CUSTOMER_API + "/hub-qc";
const HUB_CUSTOMER_API = process.env.REACT_APP_CUSTOMER_API + "/hub-customer";

const instance = axios.create({
	withCredentials: true,
	headers: {
		"Content-type": "application/json"
	}
});

interface IFilter {
	doNumber?: string;
	woNumber?: string;
	scheduleNumber?: string;
	sphNumber?: string;
	poNumber?: string;
	soNumber?: string;
	tmNumber?: string;
	customerPic?: string;
	projectName?: string;
	date?: DRange;
	salesId?: string;
	dropdown?: boolean;
	search?: string;
	category?: string;
	userName?: string;
	phone?: string;
	inOut?: string;
	page?: any;
	size?: any;
	status?: string;
	projectId?: string;
	batchingPlantId?: string;
	skuName?: string | null;
	assignee?: string;
	customerName?: string | null;
	invoiceNumber?: string | null;
}

interface IDashboard {
	startDate: string;
	endDate: string;
	type?: string;
	page?: number;
	size?: number;
	status?: string;
}

export default interface IAdditionalPricePerBatchingPlant {
	id: string;
	name: string;
	code: string;
	distance: number;
	disabled: boolean;
	long: string;
	lat: string;
	additionalPrice: number;
	priceDiffLGK: number;
	totalPriceDiff: number;
	isAgent: boolean;
}

// Function to refresh the access token using the refresh token
async function refreshAccessToken() {
	try {
		const responseRefreshToken = await instance.post<
			unknown,
			AxiosResponse<ApiResponse, unknown>
		>(
			`${COMMON_API}/common/m/auth/refresh`,
			{},
			{
				withCredentials: true
			}
		);
		const { data: ApiResponse } = responseRefreshToken;
		const resultRefreshToken = ApiResponse.data;
		const newAccToken = resultRefreshToken?.accessToken;
		localStorage.setItem("AccessToken", newAccToken);
		return newAccToken;
	} catch (err) {
		console.error("refreshAccessTokenError", err);
		throw err;
	}
}

// Axios interceptor to handle expired tokens
instance.interceptors.request.use(async (config) => {
	const accessToken = localStorage.getItem("AccessToken");
	if (accessToken) {
		config.headers.Authorization = `Bearer ${accessToken}`;
	}
	return config;
});

instance.interceptors.response.use(
	async (response: AxiosResponse<ApiResponse, AxiosError>) => {
		const { data, config: originalRequest } = response;

		if (!data.success) {
			// automatic refresh
			if (
				data?.code === "TKN001" ||
				data?.code === "TKN003" ||
				data?.code === "TKN008"
			) {
				// should be fix later
				// localStorage.clear();
				// if (window && location) {
				// 	location.reload();
				// }
				// const accessToken = await refreshAccessToken();
				// originalRequest.headers.Authorization = `Bearer ${accessToken}`;
				// originalRequest.headers.Retry = true;
				// return await axios(originalRequest);
			}

			// const accessToken = await refreshAccessToken();
			// if (accessToken) {
			// 	originalRequest.headers.Authorization = `Bearer ${accessToken}`;
			// 	originalRequest.headers.Retry = true;
			// 	return await axios(originalRequest);
			// }
		}

		return Promise.resolve(response);
	},
	async (err: AxiosError) => {
		window.parent.postMessage(
			{
				errorResponse: JSON.parse(JSON.stringify(err.response))
			},
			"*"
		);

		// const originalRequest = err.request.responseUrl
		// const data = err.response?.data as ApiResponse;
		// if (
		// 	data?.code === "TKN001" ||
		// 	data?.code === "TKN003" ||
		// 	data?.code === "TKN008"
		// ) {
		// 	const accessToken = await refreshAccessToken();
		// 	const originalRequest = err.config;
		// 	originalRequest!.headers.Authorization = `Bearer ${accessToken}`;
		// 	return await axios(originalRequest as AxiosRequestConfig<any>);
		// } else {
		// if (
		// 	(err.response?.data as any)?.error?.code === 401 &&
		// 	(err.response?.data as any)?.error?.data?.error === "invalid_token"
		// ) {
		// 	// console.log(err, "err");
		// 	// const urlOriginal = new URL(err.config?.url as string);
		// 	// // Get the search parameters from the URL
		// 	// const searchParamsOriginal = urlOriginal.searchParams;
		// 	// // Extract parameters
		// 	// const accessToken = searchParamsOriginal.get("accessToken");
		// 	// const sessionId = searchParamsOriginal.get("sessionId");
		// 	// const host = searchParamsOriginal.get("host");
		// 	// const url = new URL(`${INTEGRATOR_API}/v1/accurate/check-token`);
		// 	// const searchParams = url.searchParams;
		// 	// searchParams.append("accessToken", accessToken as string);
		// 	// searchParams.append("sessionId", sessionId as string);
		// 	// const response = await instance.get(url.toString());
		// 	// return response.data;
		// }

		return Promise.reject(err);
		// }
	}
);

class InventoryService {
	// Product API
	static createProduct = async (data: IProductPayload) => {
		const url = new URL(`${INVENTORY_API}/inventory/b/product`);
		const response = await instance({
			url: url.toString(),
			method: "POST",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
				"Content-Type": "application/json"
			}
		});
		return response.data;
	};

	static getAllProducts = async (options: IFilter) => {
		const { search, category, page, size = 15, batchingPlantId } = options;

		const url = new URL(
			`${INVENTORY_API}/inventory/b/product?page=${page}&size=${size}`
		);

		const params = url.searchParams;
		if (search) {
			params.append("search", search);
		}
		if (category) {
			params.append("category", category);
		}
		if (batchingPlantId) {
			params.append("batchingPlantId", batchingPlantId);
		}

		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});

		return response.data;
	};

	static getOneProduct = async (id: string) => {
		const response = await instance.get(
			`${INVENTORY_API}/inventory/b/product/${id}`
		);
		return response.data;
	};

	static updateProduct = async (id: string, data: IProductPayload) => {
		const url = new URL(`${INVENTORY_API}/inventory/b/product/${id}`);

		const response = await instance({
			url: url.toString(),
			method: "PUT",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
				"Content-Type": "application/json"
			}
		});
		return response.data;
	};

	// Produk_SKUS API
	static getAllProductSkus = async (product_id: string) => {
		const response = await instance.get(
			`${INVENTORY_API}/inventory/b/product-skus/${product_id}`
		);
		return response.data;
	};

	// SKU_MIXDESIGNS API
	static getAllSkuMixDesign = async (sku_id: string) => {
		const response = await instance.get(
			`${INVENTORY_API}/inventory/b/sku-mixdesigns/${sku_id}`
		);
		return response.data;
	};

	// SKU API
	static createSKU = async (data: ISKUPayload) => {
		const url = new URL(`${INVENTORY_API}/inventory/b/sku`);
		const response = await instance({
			url: url.toString(),
			method: "POST",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
				"Content-Type": "application/json"
			}
		});
		return response.data;
	};

	static getAllSKU = async (options: IFilter) => {
		const { skuName, page, size = 15, batchingPlantId } = options;

		const url = new URL(
			`${INVENTORY_API}/inventory/b/sku?page=${page}&size=${size}`
		);
		const params = url.searchParams;
		if (skuName) {
			params.append("skuName", skuName);
		}
		if (batchingPlantId) {
			params.append("batchingPlantId", batchingPlantId);
		}

		const response = await instance.get(url.toString());
		return response.data;
	};

	static getOneSKU = async (id: string) => {
		const response = await instance.get(
			`${INVENTORY_API}/inventory/b/sku/${id}`
		);
		return response.data;
	};

	static updateSKU = async (id: string, data: ISKUPayload) => {
		const url = new URL(`${INVENTORY_API}/inventory/b/sku/${id}`);

		const response = await instance({
			url: url.toString(),
			method: "PUT",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
				"Content-Type": "application/json"
			}
		});
		return response.data;
	};

	// MIX DESIGN API
	static createMixDesign = async (data: IMixDesignPayload) => {
		const url = new URL(`${INVENTORY_API}/inventory/b/mixDesign`);
		const response = await instance({
			url: url.toString(),
			method: "POST",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
				"Content-Type": "application/json"
			}
		});
		return response.data;
	};

	static updateMixDesign = async (id: string, data: IMixDesignPayload) => {
		const url = new URL(`${INVENTORY_API}/inventory/b/mixDesign/${id}`);

		const response = await instance({
			url: url.toString(),
			method: "PUT",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
				"Content-Type": "application/json"
			}
		});
		return response.data;
	};

	static getAllMixDesign = async (options: IFilter) => {
		const { search, page, size = 15, batchingPlantId } = options;
		const url = new URL(
			`${INVENTORY_API}/inventory/b/mixDesign?page=${page}&size=${size}`
		);

		const params = url.searchParams;
		if (search) {
			params.append("search", search);
		}
		const response = await instance.get(url.toString());
		return response.data;
	};

	static getOneMixDesign = async (id: string) => {
		const response = await instance.get(
			`${INVENTORY_API}/inventory/b/mixDesign/${id}`
		);
		return response.data;
	};

	static getAllCategories = async (page = 1, size = 15) => {
		const response = await instance.get(
			`${INVENTORY_API}/inventory/b/category?page=${page}&size=${size}`
		);
		return response.data;
	};

	static getAllVehicles = async (page = 1, size = 100) => {
		const response = await instance.get(
			`${INVENTORY_API}/inventory/b/vehicle?page=${page}&size=${size}`
		);
		return response.data;
	};

	static getOneVehicle = async (id: string) => {
		const response = await instance.get(
			`${INVENTORY_API}/inventory/b/vehicle/${id}`
		);
		return response.data;
	};

	static createVehicle = async (data: IVehiclePayload) => {
		const url = new URL(`${INVENTORY_API}/inventory/b/vehicle`);

		const response = await instance({
			url: url.toString(),
			method: "POST",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
				"Content-Type": "application/json"
			}
		});
		return response.data;
	};

	static updateVehicle = async (id: string, data: IVehiclePayload) => {
		const url = new URL(`${INVENTORY_API}/inventory/b/vehicle/${id}`);

		const response = await instance({
			url: url.toString(),
			method: "PUT",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
				"Content-Type": "application/json"
			}
		});
		return response.data;
	};

	static getLiveVehicles = async () => {
		const url = new URL(`${INVENTORY_API}/inventory/b/vehicle/live`);

		const response = await instance({
			url: url.toString(),
			method: "GET",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
				"Content-Type": "application/json"
			}
		});
		return response.data;
	};

	static getLiveCameras = async (ids: string) => {
		const url = new URL(`${INVENTORY_API}/inventory/b/vehicle/live/cam`);

		const response = await instance({
			url: url.toString(),
			method: "GET",
			params: {
				ids
			},
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
				"Content-Type": "application/json"
			}
		});
		return response.data;
	};

	static getAllDrivers = async (page = 1, size = 15, options: IFilter) => {
		const url = new URL(
			`${INVENTORY_API}/inventory/b/driver?page=${page}&size=${size}`
		);
		const { search } = options;

		const params = url.searchParams;
		if (search) {
			params.append("search", search);
		}
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static createDriver = async (data: PayloadDriver) => {
		const response = await instance({
			url: `${INVENTORY_API}/inventory/b/driver`,
			method: "POST",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getOneMoistureContentByBp = async (id: string) => {
		const url = new URL(
			`${INVENTORY_API}/inventory/b/moisture-content-bp/${id}`
		);
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};
}

class OrderService {
	static async getLarkUserByGroup(groupId: string, typeInterface: string) {
		const url = new URL(`${ORDER_API}/order/b/lark/group/${groupId}/user/`);
		const params = url.searchParams;

		if (typeInterface) {
			params.append("typeInterface", typeInterface);
		}

		const response = await instance({
			url: url.toString(),
			method: "GET",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	}

	static async getLarkGroup(typeInterface: string) {
		const url = new URL(`${ORDER_API}/order/b/lark/group`);
		const params = url.searchParams;

		if (typeInterface) {
			params.append("typeInterface", typeInterface);
		}

		const response = await instance({
			url: url.toString(),
			method: "GET",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	}

	static async getUserLarkByEmail(payload: {
		typeInterface: string;
		email: string;
		phone?: string;
	}) {
		const { typeInterface, email, phone } = payload;

		const url = new URL(`${ORDER_API}/order/b/lark/user/email-phone`);
		const params = url.searchParams;

		if (typeInterface) {
			params.append("typeInterface", typeInterface);
		}

		if (email) {
			params.append("email", email);
		}

		if (phone) {
			params.append("phone", phone);
		}

		const response = await instance({
			url: url.toString(),
			method: "GET",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	}
	static async updateSoNumber(id: string, number: string) {
		const url = new URL(`${ORDER_API}/order/b/purchase-order/update/${id}`);
		const response = await instance({
			url: url.toString(),
			method: "PUT",
			data: {
				number
			},
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	}
	static async updateSphNUmber(id: string, number: string) {
		const url = new URL(`${ORDER_API}/order/b/quotation/number/${id}`);
		const response = await instance({
			url: url.toString(),
			method: "PUT",
			data: {
				number
			},
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	}
	static async notifiedLoading(id: string) {
		const url = new URL(`${ORDER_API}/order/b/work-order/${id}/notify`);
		const response = await instance({
			url: url.toString(),
			method: "POST",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	}

	static async getActualDrivers() {
		const url = new URL(`${ORDER_API}/common/driver`);
		const response = await instance({
			url: url.toString(),
			method: "GET",

			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	}

	static async putRequestedProducts(
		id: string,
		data: RequestedProductsPut[]
	) {
		const url = new URL(`${ORDER_API}/order/b/quotation/product/${id}`);
		const response = await instance({
			url: url.toString(),
			method: "PUT",
			data: {
				RequestedProducts: data
			},
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	}

	static async putCharfield9(data: UpdateCharfield9Payload) {
		const url = new URL(
			`${ORDER_API}/order/b/delivery-order/hardcopy/accurate`
		);
		const response = await instance({
			url: url.toString(),
			method: "PUT",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	}

	static async patchFullPayment(id: string) {
		const url = new URL(
			`${ORDER_API}/order/b/quotation/full-payment/${id}`
		);
		const response = await instance({
			url: url.toString(),
			method: "PATCH",

			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	}

	static getAllQuotations = async (page = 1, size = 15, options: IFilter) => {
		const url = new URL(
			`${ORDER_API}/order/b/quotation?page=${page}&size=${size}`
		);
		const {
			sphNumber,
			projectName,
			projectId,
			date,
			salesId,
			batchingPlantId,
			customerName,
			status
		} = options;
		const params = url.searchParams;

		if (sphNumber) {
			params.append("sphNumber", sphNumber);
		}
		if (projectName) {
			params.append("projectName", projectName);
		}
		if (projectId) {
			params.append("projectId", projectId);
		}
		if (date?.endDate) {
			params.append(
				"startDate",
				date.startDate?.valueOf().toString() as string
			);
			params.append(
				"endDate",
				date.endDate?.valueOf().toString() as string
			);
		}
		if (salesId) {
			params.append("salesId", salesId);
		}
		if (batchingPlantId) {
			params.append("batchingPlantId", batchingPlantId);
		}

		if (customerName) {
			params.append("customerName", customerName);
		}

		if (status) {
			params.append("status", status);
		}

		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getOneQuotation = async (id: string) => {
		const url = new URL(`${ORDER_API}/order/b/quotation/${id}`);
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static putQuotation = async (id: string, data: unknown) => {
		const url = new URL(`${ORDER_API}/order/b/quotation/${id}`);

		const response = await instance({
			url: url.toString(),
			method: "PUT",
			data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static rejectQuotation = async (id: string) => {
		const url = new URL(`${ORDER_API}/order/b/quotation/reject/${id}`);

		const response = await instance({
			url: url.toString(),
			method: "PUT",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static quotationPdfGenerate = async (id: string) => {
		const url = new URL(`${ORDER_API}/order/b/quotation/${id}/pdf`);

		const response = await instance({
			url: url.toString(),
			method: "POST",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static createQuotation = async (payload: CreateQuotationModel) => {
		const response = await instance({
			url: `${ORDER_API}/order/b/quotation`,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			},
			method: "POST",
			data: payload
		});
		return response.data;
	};

	static editQuotationBP = async (id: string, data: updateQuotationBP) => {
		const url = new URL(
			`${ORDER_API}/order/b/quotation/batching-plant/${id}`
		);

		const response = await instance({
			url: url.toString(),
			method: "PUT",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
				"Content-Type": "application/json"
			}
		});
		return response.data;
	};

	static updateQuotationAccurate = async (
		id: string,
		data: AccurateQuotation
	) => {
		const url = new URL(`${ORDER_API}/order/b/quotation/${id}/accurate`);

		const response = await instance({
			url: url.toString(),
			method: "PUT",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
				"Content-Type": "application/json"
			}
		});
		return response.data;
	};

	static createPurchaseOrder = async (payload: CreatePurchaseOrderModel) => {
		const response = await instance({
			url: `${ORDER_API}/order/b/purchase-order`,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			},
			method: "POST",
			data: payload
		});
		return response.data;
	};

	static signSO = async (id: string, payload: any) => {
		const url = new URL(`${ORDER_API}/order/b/purchase-order/docs/${id}`);

		const response = await instance({
			url: url.toString(),
			method: "PUT",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			},
			data: payload
		});

		return response;
	};

	static putPurchaseOrder = async (id: string, data: purchaseOrderFile[]) => {
		const url = new URL(
			`${ORDER_API}/order/b/purchase-order/po-file/${id}`
		);

		const response = await instance({
			url: url.toString(),
			method: "PUT",
			data: {
				files: data
			},
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getAllPOs = async (page = 1, size = 15, options: IFilter) => {
		const url = new URL(
			`${ORDER_API}/order/b/purchase-order?page=${page}&size=${size}`
		);
		const {
			poNumber,
			projectName,
			date,
			salesId,
			batchingPlantId,
			customerName,
			status
		} = options;
		const params = url.searchParams;
		if (poNumber) {
			params.append("poNumber", poNumber);
		}
		if (projectName) {
			params.append("projectName", projectName);
		}
		if (date?.endDate) {
			params.append(
				"startDate",
				date.startDate?.valueOf().toString() as string
			);
			params.append(
				"endDate",
				date.endDate?.valueOf().toString() as string
			);
		}
		if (salesId) {
			params.append("salesId", salesId);
		}
		if (batchingPlantId) {
			params.append("batchingPlantId", batchingPlantId);
		}
		if (customerName) {
			params.append("customerName", customerName);
		}

		if (status) {
			params.append("status", status);
		}
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getAllSOs = async (page = 1, size = 15, options: IFilter) => {
		const url = new URL(
			`${ORDER_API}/order/b/purchase-order/so?page=${page}&size=${size}`
		);
		const {
			soNumber,
			projectName,
			customerPic,
			date,
			salesId,
			batchingPlantId,
			status
		} = options;
		const params = url.searchParams;
		if (soNumber) {
			params.append("soNumber", soNumber);
		}
		if (projectName) {
			params.append("projectName", projectName);
		}
		if (customerPic) {
			params.append("customerPic", customerPic);
		}
		if (date?.endDate) {
			params.append(
				"startDate",
				date.startDate?.valueOf().toString() as string
			);
			params.append(
				"endDate",
				date.endDate?.valueOf().toString() as string
			);
		}
		if (salesId) {
			params.append("salesId", salesId);
		}
		if (batchingPlantId) {
			params.append("batchingPlantId", batchingPlantId);
		}

		if (status) {
			params.append("status", status);
		}
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getOnePO = async (id: string) => {
		const url = new URL(`${ORDER_API}/order/b/purchase-order/${id}`);
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static updatePO = async (
		id: string,
		status: "CONFIRMED" | "APPROVED" | "DECLINED"
	) => {
		const url = new URL(`${ORDER_API}/order/b/purchase-order/${id}`);
		const response = await instance({
			url: url.toString(),
			method: "PUT",
			data: {
				status
			},
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static updateRequestedProductSKU = async (id: string, payload: any) => {
		const url = new URL(`${ORDER_API}/order/b/sale-order/${id}/update-sku`);
		const response = await instance({
			url: url.toString(),
			method: "PUT",
			data: payload,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static uploadSignedSO = async (id: string, files: PayloadSignedSO[]) => {
		const url = new URL(`${ORDER_API}/order/b/purchase-order/docs/${id}`);
		const response = await instance({
			url: url.toString(),
			method: "PUT",
			data: {
				poDocs: files
			},
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getAllDeposits = async (page = 1, size = 15, options: IFilter) => {
		const url = new URL(
			`${ORDER_API}/order/b/deposit?page=${page}&size=${size}`
		);
		const { date, salesId } = options;
		const params = url.searchParams;
		if (date?.endDate) {
			params.append(
				"startDate",
				date.startDate?.valueOf().toString() as string
			);
			params.append(
				"endDate",
				date.endDate?.valueOf().toString() as string
			);
		}
		if (salesId) {
			params.append("salesId", salesId);
		}
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getOneDeposit = async (id: string) => {
		const url = new URL(`${ORDER_API}/order/b/deposit/${id}`);
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static patchDeposit = async (
		id: string,
		status: "CONFIRMED" | "APPROVED" | "DECLINED"
	) => {
		const url = new URL(`${ORDER_API}/order/b/deposit/${id}/status`);
		const response = await instance({
			url: url.toString(),
			method: "PATCH",
			data: {
				status
			},
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getAllSchedules = async (page = 1, size = 15, options: IFilter) => {
		const url = new URL(
			`${ORDER_API}/order/b/schedule?page=${page}&size=${size}`
		);
		const {
			scheduleNumber,
			projectName,
			date,
			salesId,
			dropdown,
			batchingPlantId,
			customerName
		} = options;
		const params = url.searchParams;
		if (scheduleNumber) {
			params.append("scheduleNumber", scheduleNumber);
		}
		if (projectName) {
			params.append("projectName", projectName);
		}
		if (date?.endDate) {
			params.append(
				"startDate",
				date.startDate?.valueOf().toString() as string
			);
			params.append(
				"endDate",
				date.endDate?.valueOf().toString() as string
			);
		}
		if (salesId) {
			params.append("salesId", salesId);
		}
		if (dropdown) {
			params.append("dropdown", String(dropdown));
		}
		if (batchingPlantId) {
			params.append("batchingPlantId", batchingPlantId);
		}
		if (customerName) {
			params.append("customerName", customerName);
		}
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});

		return response.data;
	};
	static getOneSchedule = async (id: string) => {
		const url = new URL(`${ORDER_API}/order/b/schedule/${id}`);
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};
	static patchSchedule = async (
		id: string,
		status: "APPROVED" | "DECLINED"
	) => {
		const url = new URL(`${ORDER_API}/order/b/schedule/${id}/status`);
		const response = await instance({
			url: url.toString(),
			method: "PATCH",
			data: {
				status
			},
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static patchWO = async (id: string, status: "CANCELLED") => {
		const url = new URL(`${ORDER_API}/order/b/work-order/${id}/status`);
		const response = await instance({
			url: url.toString(),
			method: "PATCH",
			data: {
				status
			},
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getAllDO = async (page = 1, size = 15, options: IFilter) => {
		const url = new URL(
			`${ORDER_API}/order/b/delivery-order?page=${page}&size=${size}`
		);
		const {
			doNumber,
			date,
			salesId,
			search,
			status,
			projectId,
			batchingPlantId
		} = options;
		const params = url.searchParams;
		if (doNumber) {
			params.append("doNumber", doNumber);
		}
		if (date?.endDate) {
			params.append(
				"startDate",
				date.startDate?.valueOf().toString() as string
			);
			params.append(
				"endDate",
				date.endDate?.valueOf().toString() as string
			);
		}
		if (salesId) {
			params.append("salesId", salesId);
		}
		if (search) {
			params.append("search", search);
		}
		if (status) {
			params.append("status", status);
		}
		if (projectId) {
			params.append("projectId", projectId);
		}
		if (batchingPlantId) {
			params.append("batchingPlantId", batchingPlantId);
		}
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getAllDODropdown = async (page = 1, size = 15, options: IFilter) => {
		const url = new URL(
			`${ORDER_API}/order/b/delivery-order/dropdown?page=${page}&size=${size}`
		);
		const { date, salesId, search, status, projectId, batchingPlantId } =
			options;
		const params = url.searchParams;
		if (date?.endDate) {
			params.append(
				"startDate",
				date.startDate?.valueOf().toString() as string
			);
			params.append(
				"endDate",
				date.endDate?.valueOf().toString() as string
			);
		}
		if (salesId) {
			params.append("salesId", salesId);
		}
		if (search) {
			params.append("search", search);
		}
		if (status) {
			params.append("status", status);
		}
		if (projectId) {
			params.append("projectId", projectId);
		}
		if (batchingPlantId) {
			params.append("batchingPlantId", batchingPlantId);
		}
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getOneDO = async (id: string) => {
		const url = new URL(`${ORDER_API}/order/b/delivery-order/${id}`);
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getDONumbers = async (batchingPlantId: string) => {
		const url = new URL(
			`${ORDER_API}/order/b/delivery-order/numbers/${batchingPlantId}`
		);
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static createDO = async (
		payload: PayloadCreateDO & Partial<PayloadCreateAccurateDO>
	) => {
		const url = new URL(`${ORDER_API}/order/b/delivery-order`);
		const response = await instance({
			url: url.toString(),
			method: "POST",
			data: payload,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static updateDO = async (id: string, payload: PayloadCreateDO) => {
		const url = new URL(`${ORDER_API}/order/b/delivery-order/${id}`);
		const response = await instance({
			url: url.toString(),
			method: "PUT",
			data: payload,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	// Work Order API calls
	static createWorkOrder = async (payload: PayloadCreateDO) => {
		const url = new URL(`${ORDER_API}/order/b/work-order`);
		const response = await instance({
			url: url.toString(),
			method: "POST",
			data: payload,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getAllWO = async (page = 1, size = 15, options: IFilter) => {
		const url = new URL(
			`${ORDER_API}/order/b/work-order?page=${page}&size=${size}`
		);
		const { woNumber, date, salesId, search, status, batchingPlantId } =
			options;
		const params = url.searchParams;
		if (woNumber) {
			params.append("woNumber", woNumber);
		}
		if (date?.endDate) {
			params.append(
				"startDate",
				date.startDate?.valueOf().toString() as string
			);
			params.append(
				"endDate",
				date.endDate?.valueOf().toString() as string
			);
		}
		if (salesId) {
			params.append("salesId", salesId);
		}
		if (search) {
			params.append("search", search);
		}
		if (status) {
			params.append("status", status);
		}

		if (batchingPlantId) {
			params.append("batchingPlantId", batchingPlantId);
		}
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getOneWO = async (id: string) => {
		const url = new URL(`${ORDER_API}/order/b/work-order/${id}`);
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data.data;
	};

	static editWO = async (id: string, payload: ApiWorkOrder) => {
		const url = new URL(`${ORDER_API}/order/b/work-order/${id}`);
		const response = await instance({
			url: url.toString(),
			method: "PUT",
			data: payload,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static filesWO = async (id: string, payload: FileWO) => {
		const url = new URL(`${ORDER_API}/order/b/work-order/wo-file/${id}`);
		const response = await instance({
			url: url.toString(),
			method: "POST",
			data: payload,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static updateFileWo = async (id: string, payload: FileWO) => {
		const url = new URL(`${ORDER_API}/order/b/work-order/wo-file/${id}`);
		const response = await instance({
			url: url.toString(),
			method: "PUT",
			data: payload,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};
}

class CommonService {
	static getBP = async () => {
		const response = await instance({
			url: `${COMMON_API}/common/batching-plant`,
			method: "GET",
			withCredentials: true,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};
	static getDispatchers = async ({
		page,
		size,
		search
	}: {
		size?: number;
		page?: number;
		search?: string | null;
	}) => {
		const response = await instance({
			url: `${COMMON_API}/common/b/dispatcher`,
			method: "GET",
			withCredentials: true,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			},
			params: {
				page,
				size,
				search
			}
		});
		return response.data;
	};

	static async postDispatcher(payload: { name: string }) {
		const response = await instance({
			url: `${COMMON_API}/common/b/dispatcher`,
			method: "POST",
			withCredentials: true,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			},
			data: payload
		});
		return response.data;
	}

	static getProjectsByCustomer = async ({
		customerId,
		page,
		size,
		search
	}: {
		customerId: string;
		page: number;
		size: number;
		search?: string | null;
	}) => {
		const url = new URL(
			`${COMMON_API}/common/b/project/customer/${customerId}?page=${page}&size=${size}`
		);
		const params = url.searchParams;
		if (search) {
			params.append("search", search);
		}
		const response = await instance({
			url: url.toString(),
			method: "GET",
			withCredentials: true,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getOneProject = async (id: string) => {
		const response = await instance({
			url: `${COMMON_API}/common/project/${id}`,
			method: "GET",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getSpecimentByProject = async (id: string) => {
		const response = await instance({
			url: `${QC_API}/v1/specimen-request/project/${id}`,
			method: "GET",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static approveSpecimenRequest = async (id: string) => {
		const response = await instance({
			url: `${QC_API}/v1/specimen-request/${id}/approve`,
			method: "PUT",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static rejectSpecimenRequest = async (id: string) => {
		const response = await instance({
			url: `${QC_API}/v1/specimen-request/${id}/reject`,
			method: "PUT",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getRoutesByProject = async (projectId: string) => {
		const response = await instance({
			url: `${COMMON_API}/common/b/route/project/${projectId}`,
			method: "GET",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getRoutesBySchedule = async (scheduleId: string) => {
		const response = await instance({
			url: `${COMMON_API}/common/b/route/schedule/${scheduleId}`,
			method: "GET",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static parseGoogleMaps = async ({
		url,
		projectId,
		batchingPlantId,
		updateShippingAddress
	}: {
		url: string;
		projectId?: string;
		batchingPlantId?: string;
		updateShippingAddress?: boolean;
	}) => {
		const response = await instance({
			url: `${COMMON_API}/common/b/route/parse-google-maps`,
			method: "POST",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			},
			data: {
				url,
				projectId,
				batchingPlantId,
				updateShippingAddress
			}
		});
		return response.data;
	};

	static createOrUpdateRoute = async ({
		url,
		batchingPlantId,
		projectId,
		route,
		isSurveyed,
		updateShippingAddress
	}: {
		url: string;
		batchingPlantId: string;
		projectId: string;
		route: any;
		isSurveyed?: boolean;
		updateShippingAddress?: boolean;
	}) => {
		const response = await instance({
			url: `${COMMON_API}/common/b/route`,
			method: "POST",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			},
			data: {
				url,
				batchingPlantId,
				projectId,
				route,
				isSurveyed,
				updateShippingAddress
			}
		});
		return response.data;
	};

	static async mergeCustomer({
		masterCustomerId,
		slaveCustomerIds
	}: {
		slaveCustomerIds: string[];
		masterCustomerId: string;
	}) {
		const response = await instance({
			url: `${COMMON_API}/common/b/customer/merge`,
			method: "POST",
			withCredentials: true,
			data: { slaveCustomerIds, masterCustomerId },
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	}
	static async mergeProjects({
		masterProjectId,
		slaveProjectIds
	}: {
		slaveProjectIds: string[];
		masterProjectId: string;
	}) {
		const response = await instance({
			url: `${COMMON_API}/common/b/project/merge`,
			method: "POST",
			withCredentials: true,
			data: { slaveProjectIds, masterProjectId },
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	}

	static getPurchaseOrderByProject = async (projectId: string) => {
		const response = await instance({
			url: `${ORDER_API}/order/b/purchase-order/po/${projectId}`,
			method: "GET",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getSaleOrderByOurchaseOrder = async (purchaseOrderId: string) => {
		const response = await instance({
			url: `${ORDER_API}/order/b/sale-order/findbypo/${purchaseOrderId}`,
			method: "GET",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static createSchedule = async (payload: any) => {
		const response = await instance({
			url: `${ORDER_API}/order/b/schedule`,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			},
			method: "POST",
			data: payload
		});
		return response.data;
	};

	static editSchedule = async (payload: any, id: string) => {
		const response = await instance({
			url: `${ORDER_API}/order/b/schedule/${id}/update`,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			},
			method: "PUT",
			data: payload
		});
		return response.data;
	};

	static getAllRoles = async () => {
		const response = await instance({
			url: `${COMMON_API}/common/b/user/roles`,
			method: "GET",
			withCredentials: true,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getOneUser = async (id: string) => {
		const response = await instance({
			url: `${COMMON_API}/common/b/user/${id}`,
			method: "GET",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static updateUser = async (id: string, data: PayloadCreateUser) => {
		const response = await instance({
			url: `${COMMON_API}/common/b/user/${id}`,
			method: "PUT",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static login = async (payload: loginAuth) => {
		const response = await instance({
			url: `${COMMON_API}/common/b/auth/login`,
			method: "POST",
			data: payload,
			withCredentials: true
		});
		return response.data;
	};

	static refresh = async () => {
		const response = await instance({
			url: `${COMMON_API}/common/m/auth/refresh`,
			method: "POST",
			withCredentials: true
		});
		return response.data;
	};

	static logout = async () => {
		const response = await instance({
			url: `${COMMON_API}/common/m/auth/logout`,
			method: "POST",
			withCredentials: true
		});
		return response.data;
	};

	static getUsers = async (
		page = 1,
		size = 200,
		group?: string,
		options?: IFilter
	) => {
		const url = new URL(
			`${COMMON_API}/common/b/user?page=${page}&size=${size}`
		);
		const params = url.searchParams;

		if (options?.userName) {
			params.append("userName", options.userName);
		}
		if (options?.phone) {
			params.append("phone", options.phone);
		}
		if (group) {
			params.append("group", group);
		}

		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});

		return response.data;
	};

	static getAllDrivers = async (page = 1, size = 15, date?: DRange) => {
		const url = new URL(
			`${COMMON_API}/common/m/driver-list?page=${page}&size=${size}`
		);
		const params = url.searchParams;
		if (date?.endDate) {
			params.append(
				"startDate",
				date.startDate?.valueOf().toString() as string
			);
			params.append(
				"endDate",
				date.endDate?.valueOf().toString() as string
			);
		}
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getAllProject = async (page = 1, size = 15, search?: string) => {
		const url = new URL(
			`${COMMON_API}/common/b/project?page=${page}&size=${size}`
		);
		const params = url.searchParams;
		if (search) {
			params.append("search", search);
		}
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getAllProjectCustomer = async (
		page = 1,
		size = 100,
		search?: string | null,
		customerId?: string
	) => {
		const url = new URL(
			`${COMMON_API}/common/b/project/customer?page=${page}&size=${size}`
		);
		const params = url.searchParams;
		if (search) {
			params.append("search", search);
		}
		if (customerId) {
			params.append("customerId", customerId);
		}
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static uploadFile = async (files: FormData) => {
		const url = new URL(`${COMMON_API}/common/file/upload`);
		const response = await instance({
			url: url.toString(),
			method: "POST",
			data: files,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
				"Content-Type": "multipart/form-data"
			}
		});
		return response.data;
	};

	static register = async (payload: registerUser) => {
		const response = await instance({
			url: `${COMMON_API}/common/m/auth/register`,
			method: "POST",
			data: payload
		});
		return response.data;
	};

	static getAllCustomer = async (page = 1, size = 15, options: IFilter) => {
		const url = new URL(
			`${COMMON_API}/common/b/customer?page=${page}&size=${size}`
		);
		const { date, dropdown, salesId, search } = options;
		const params = url.searchParams;
		if (search) {
			params.append("search", search);
		}
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static createPIC = async (data: PayloadCreatePIC) => {
		const response = await instance({
			url: `${COMMON_API}/common/m/pic`,
			method: "POST",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static updatePIC = async (id: string, data: PayloadCreatePIC) => {
		const response = await instance({
			url: `${COMMON_API}/common/m/pic/${id}`,
			method: "PUT",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static createAddress = async (data: PayloadCreateAddress) => {
		const response = await instance({
			url: `${COMMON_API}/common/address`,
			method: "POST",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getAddress = async (id: string) => {
		const response = await instance({
			url: `${COMMON_API}/common/address/${id}`,
			method: "GET",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static updateAddress = async (id: string, data: PayloadCreateAddress) => {
		const response = await instance({
			url: `${COMMON_API}/common/address/${id}`,
			method: "PUT",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static createCustomer = async (data: PayloadCustomer) => {
		const response = await instance({
			url: `${COMMON_API}/common/b/customer`,
			method: "POST",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static updateCustomer = async (id: string, data: PayloadCustomer) => {
		const response = await instance({
			url: `${COMMON_API}/common/b/customer/${id}`,
			method: "PUT",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getOneCustomer = async (
		id: string
	): Promise<ApiResponsePlain<CustomerDetailSelected>> => {
		const url = new URL(`${COMMON_API}/common/b/customer/${id}`);
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getSummary = async (id: string) => {
		const url = new URL(`${COMMON_API}/common/b/customer/${id}/summary`);
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getPlaces = async (search: string) => {
		const url = new URL(`${COMMON_API}/common/map/places?search=${search}`);

		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getPlaceDetail = async (id: string) => {
		const url = new URL(`${COMMON_API}/common/map/places/${id}`);

		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getDistance = async (lat: string, lon: string, distance: string) => {
		const url = new URL(`${COMMON_API}/common/map/coordinates/`);

		const params = new URLSearchParams({
			lon: lon,
			lat: lat,
			distance: distance
		});

		url.search = params.toString();

		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getProjectInvoice = async (options: IFilter) => {
		const url = new URL(`${COMMON_API}/common/b/project/dropdown`);
		const params = url.searchParams;
		const { search, page, size } = options;

		if (search) {
			params.append("search", search);
		}

		if (page) {
			params.append("page", page);
		}

		if (size) {
			params.append("size", size);
		}

		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static createProject = async (payload: ICreateProject) => {
		const url = new URL(`${COMMON_API}/common/b/project`);

		const response = await instance({
			url: url.toString(),
			method: "POST",
			data: payload,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};
}

class ProductivityService {
	static async approveTrialMix(id: string, status: string, payload: any) {
		const url = new URL(
			`${PROD_API}/productivity/m/trial-mix/${id}/${status}`
		);
		const response = await instance({
			url: url.toString(),
			method: "POST",
			data: payload,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});

		return response.data;
	}

	static async updateTrialMix(id: string, status: string) {
		const url = new URL(
			`${PROD_API}/productivity/m/trial-mix/${id}/${status}`
		);
		const response = await instance({
			url: url.toString(),
			method: "POST",
			data: {
				status
			},
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});

		return response.data;
	}

	static async getOneTrialMix(id: string) {
		const url = new URL(`${PROD_API}/productivity/b/trial-mix/${id}`);

		const response = await instance({
			url: url.toString(),
			method: "GET",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});

		return response.data;
	}

	static async getAllTrialMix(page = 1, size = 15, options: IFilter) {
		const url = new URL(
			`${PROD_API}/productivity/b/trial-mix?page=${page}&size=${size}`
		);

		const params = url.searchParams;

		if (options?.tmNumber) {
			params.append("tmNumber", options?.tmNumber);
		}

		if (options.date?.endDate) {
			params.append(
				"startDate",
				options?.date?.startDate?.valueOf().toString() as string
			);
			params.append(
				"endDate",
				options?.date?.endDate?.valueOf().toString() as string
			);
		}

		if (options.batchingPlantId) {
			params.append("batchingPlantId", options.batchingPlantId);
		}

		const response = await instance({
			url: url.toString(),
			method: "GET",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});

		return response.data;
	}
	static getAllVisitations = async (
		page = 1,
		size = 15,
		options: IFilter
	) => {
		const url = new URL(
			`${PROD_API}/productivity/b/visitation/all-visitation?page=${page}&size=${size}`
		);
		const params = url.searchParams;
		const { date, assignee, salesId, batchingPlantId } = options;

		if (date?.endDate) {
			params.append(
				"startDate",
				date.startDate?.valueOf().toString() as string
			);
			params.append(
				"endDate",
				date.endDate?.valueOf().toString() as string
			);
		}

		if (batchingPlantId) {
			params.append("batchingPlantId", batchingPlantId);
		}

		if (assignee) {
			params.append("assignBy", assignee);
		}

		if (salesId) {
			params.append("salesId", salesId);
		}

		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};
	static getOneVisitation = async (id: string) => {
		const url = new URL(`${PROD_API}/productivity/b/visitation/${id}`);
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static deleteVisitation = async (id: string) => {
		const url = new URL(`${PROD_API}/productivity/b/visitation/${id}`);
		const response = await instance.delete(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static assignVisitation = async (data: ApiCreateVisitationAssign) => {
		const url = new URL(`${PROD_API}/productivity/b/visitation/assign`);

		const response = await instance({
			url: url.toString(),
			method: "POST",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
				"Content-Type": "application/json"
			}
		});
		return response.data;
	};
}

class FinanceService {
	static sendNotified = async (id: string) => {
		const url = new URL(`${HUB_CUSTOMER_API}/v1/accurate/file-invoice/${id}`);

		const response = await instance({
			url: url.toString(),
			method: "POST",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};
	static uploadFileInvoice = async (id: string, files: any) => {
		const url = new URL(`${FINANCE_API}/finance/b/invoice/upload/${id}`);

		const response = await instance({
			url: url.toString(),
			method: "PUT",
			data: {
				files
			},
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});

		return response.data;
	};
	static getAllInvoice = async (page = 1, size = 15, options: IFilter) => {
		const url = new URL(
			`${FINANCE_API}/finance/b/invoice?page=${page}&size=${size}`
		);

		const params = url.searchParams;
		const {
			customerName,
			invoiceNumber,
			date,
			projectId,
			batchingPlantId
		} = options;

		if (customerName) {
			params.append("customerName", customerName);
		}

		if (invoiceNumber) {
			params.append("number", invoiceNumber);
		}

		if (date?.endDate) {
			params.append(
				"startDateIssued",
				date.startDate?.valueOf().toString() as string
			);
			params.append(
				"endDateIssued",
				date.endDate?.valueOf().toString() as string
			);
		}

		if (projectId) {
			params.append("projectId", projectId);
		}
		if (batchingPlantId) {
			params.append("batchingPlantId", batchingPlantId);
		}

		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static getCustomerTransactions = async (id: string, options: IFilter) => {
		const url = new URL(
			`${FINANCE_API}/finance/b/customer/${id}/transaction`
		);
		const { inOut, size, page } = options;
		const params = url.searchParams;

		if (inOut) {
			params.append("inOut", inOut);
			params.append("size", size);
			params.append("page", page);
		}

		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});

		return response.data;
	};

	static createAdjustment = async (data: PayloadAdjustment) => {
		const response = await instance({
			url: `${FINANCE_API}/finance/b/transaction`,
			method: "POST",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static createInvoice = async (data: CreateInvoiceModel) => {
		const response = await instance({
			url: `${FINANCE_API}/finance/b/invoice`,
			method: "POST",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};
	static updateInvoice = async (id: string, data: UpdateInvoiceModel) => {
		const response = await instance({
			url: `${FINANCE_API}/finance/b/invoice/${id}`,
			method: "PUT",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};
	static getAllPayments = async (page = 1, size = 15, options: IFilter) => {
		const url = new URL(`${FINANCE_API}/finance/b/payment`);
		const { search, batchingPlantId, customerName } = options;

		const params = url.searchParams;
		params.append("page", `${page}`);
		params.append("size", `${size}`);
		// if (type) {
		// 	params.append("type", type);
		// }
		if (search) {
			params.append("search", search);
		}
		if (batchingPlantId) {
			params.append("batchingPlantId", batchingPlantId);
		}
		if (customerName) {
			params.append("customerName", customerName);
		}
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};
	static getOnePayment = async (id: string) => {
		const url = new URL(`${FINANCE_API}/finance/b/payment/${id}`);
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};
	static getOnInvoice = async (id: string) => {
		const response = await instance({
			url: `${FINANCE_API}/finance/b/invoice/${id}`,
			method: "GET",
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};
	static updatePayment = async (
		id: string,
		status: "APPROVED" | "DECLINED" | "SUBMITTED"
	) => {
		const url = new URL(`${FINANCE_API}/finance/b/payment/${id}/status`);
		const response = await instance({
			url: url.toString(),
			method: "PATCH",
			data: { status },
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static createPayment = async (data: Partial<PayloadPayment>) => {
		const response = await instance({
			url: `${FINANCE_API}/finance/b/payment`,
			method: "POST",
			data: data,
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};

	static updatePaymentNumber = async (
		id: string,
		number?: string,
		batchingPlantId?: string,
		customerAccurateId?: number | string,
		description?: string,
		status?: string
	) => {
		const url = new URL(`${FINANCE_API}/finance/b/payment/${id}`);
		const response = await instance({
			url: url.toString(),
			method: "PUT",
			data: {
				number,
				batchingPlantId,
				customerAccurateId,
				description,
				status
			},
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response.data;
	};
}

const gpskuToken =
	"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MTE2MTMwNTksInVzZXJfaWQiOjc1NTN9.4gXV4cigZfHxXOe174mmD7G8uLI_b0846QhFqnoUKtA";
class GpskuService {
	static getOnePosition = async (deviceId: string) => {
		// const response = await instance({
		// 	url: `https://server2.gpsku.co.id:8088/v1/deviceactivetrack/${deviceId}`,
		// 	method: "GET",
		// 	headers: {
		// 		Authorization: `Bearer ${gpskuToken}`,
		// 	},
		// 	withCredentials: false,
		// });
		const response = await fetch(
			`https://server2.gpsku.co.id:8088/v1/deviceactivetrack/${deviceId}`,
			{
				referrerPolicy: "origin",
				headers: {
					Authorization: `Bearer ${gpskuToken}`
					// "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
				}
			}
		);
		return response;
	};
}

class DashboardService {
	static getSalesFunnel = async ({
		startDate,
		endDate,
		type
	}: IDashboard) => {
		const url = new URL(`${COMMON_API}/common/b/dashboard`);
		const params = url.searchParams;

		const offsetGMT7 = 7 * 60 * 60 * 1000;
		const paramsStartDate = moment(startDate).valueOf().toString();
		const paramsEndDate = moment(endDate).valueOf().toString();

		params.append(
			"startDate",
			`${+paramsStartDate + offsetGMT7}` as string
		);
		params.append("endDate", `${+paramsEndDate + offsetGMT7}` as string);
		if (type) {
			params.append("type", `${type}`);
		}

		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response;
	};

	static async bestSellingProduct({
		startDate,
		endDate,
		page,
		size
	}: IDashboard) {
		const url = new URL(`${COMMON_API}/common/b/dashboard/products`);
		const params = url.searchParams;

		const offsetGMT7 = 7 * 60 * 60 * 1000;
		const paramsStartDate = moment(startDate).valueOf().toString();
		const paramsEndDate = moment(endDate).valueOf().toString();

		params.append(
			"startDate",
			`${+paramsStartDate + offsetGMT7}` as string
		);
		params.append("endDate", `${+paramsEndDate + offsetGMT7}` as string);

		if (page) {
			params.append("page", `${page}`);
		}
		if (size) {
			params.append("size", `${size}`);
		}

		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response;
	}

	static async visitationStatusCount({
		startDate,
		endDate,
		type,
		page,
		size,
		status
	}: IDashboard) {
		const url = new URL(`${COMMON_API}/common/b/dashboard/visitation`);
		const params = url.searchParams;

		const offsetGMT7 = 7 * 60 * 60 * 1000;
		const paramsStartDate = moment(startDate).valueOf().toString();
		const paramsEndDate = moment(endDate).valueOf().toString();

		params.append(
			"startDate",
			`${+paramsStartDate + offsetGMT7}` as string
		);
		params.append("endDate", `${+paramsEndDate + offsetGMT7}` as string);

		if (type) {
			params.append("type", `${type}`);
		}
		if (page) {
			params.append("page", `${page}`);
		}
		if (size) {
			params.append("size", `${size}`);
		}
		if (status) {
			params.append("status", `${status}`);
		}
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response;
	}

	static async depositCount({
		page,
		size,
		startDate,
		endDate,
		type
	}: IDashboard) {
		const url = new URL(`${COMMON_API}/common/b/dashboard/deposit`);
		const params = url.searchParams;

		const offsetGMT7 = 7 * 60 * 60 * 1000;
		const paramsStartDate = moment(startDate).valueOf().toString();
		const paramsEndDate = moment(endDate).valueOf().toString();

		params.append(
			"startDate",
			`${+paramsStartDate + offsetGMT7}` as string
		);
		params.append("endDate", `${+paramsEndDate + offsetGMT7}` as string);

		if (type) {
			params.append("type", `${type}`);
		}
		if (page) {
			params.append("page", `${page}`);
		}
		if (size) {
			params.append("size", `${size}`);
		}

		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response;
	}

	static async salesSoCount({
		page,
		size,
		startDate,
		endDate,
		type
	}: IDashboard) {
		const url = new URL(`${COMMON_API}/common/b/dashboard/sales-so`);
		const params = url.searchParams;

		const offsetGMT7 = 7 * 60 * 60 * 1000;
		const paramsStartDate = moment(startDate).valueOf().toString();
		const paramsEndDate = moment(endDate).valueOf().toString();

		params.append(
			"startDate",
			`${+paramsStartDate + offsetGMT7}` as string
		);
		params.append("endDate", `${+paramsEndDate + offsetGMT7}` as string);

		if (type) {
			params.append("type", `${type}`);
		}
		if (page) {
			params.append("page", `${page}`);
		}
		if (size) {
			params.append("size", `${size}`);
		}

		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});
		return response;
	}
}

class CustomerService {
	static getAdditionalPricePerBatchingPlant = async (
		projectId: string
	): Promise<IAdditionalPricePerBatchingPlant[]> => {
		const url = new URL(`${CUSTOMER_API}/customer/b/batching-plant`);
		const params = url.searchParams;

		params.append("projectId", projectId);

		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});

		if (!response.status) {
			throw new Error(
				"Failed to get additional price per batching plant"
			);
		}

		console.log("RESPONSE DATA", response.data);

		return response.data.data;
	};
}
// https://apis.oreo.brik.id/hub-integrator/
class AccurateService {
	static getAccurateToken = async (code: string) => {
		const url = new URL(`${INTEGRATOR_API}/v1/accurate/auth`);
		const response = await instance.post(url.toString(), {
			code
		});

		return response.data;
	};

	static checkToken = async () => {
		const url = new URL(`${INTEGRATOR_API}/v1/accurate/check-token`);
		const params = url.searchParams;
		const accurateCreds = getAccurateCreds();
		params.append("accessToken", accurateCreds?.accessToken as string);
		params.append("sessionId", accurateCreds?.session as string);
		const response = await instance.get(url.toString());

		return response.data;
	};

	static async getAccurateCustomers({
		page,
		keywords
	}: {
		page?: string | number;
		keywords?: string;
	}) {
		const url = new URL(`${INTEGRATOR_API}/v1/accurate/customer`);
		const params = url.searchParams;
		const accurateCreds = getAccurateCreds();
		params.append("accessToken", accurateCreds?.accessToken as string);
		params.append("sessionId", accurateCreds?.session as string);
		params.append("host", accurateCreds?.host as string);
		if (page) {
			params.append("page", page as string);
		}
		if (keywords) {
			params.append("keywords", keywords as string);
		}

		const response = await instance.get(url.toString());

		return response.data;
	}

	static async getAccurateCustomer({ id }: { id: number }) {
		const url = new URL(`${INTEGRATOR_API}/v1/accurate/customer/${id}`);
		const params = url.searchParams;
		const accurateCreds = getAccurateCreds();
		params.append("accessToken", accurateCreds?.accessToken as string);
		params.append("sessionId", accurateCreds?.session as string);
		params.append("host", accurateCreds?.host as string);

		const response = await instance.get(url.toString());

		return response.data;
	}

	static async getAccurateDispatcherDriver() {
		const url = new URL(`${INTEGRATOR_API}/v1/accurate/driver-dispatcher`);
		const response = await instance.get(url.toString());

		return response.data;
	}
	static async getAccurateSalesOrder({
		keywords,
		customerId,
		page
	}: {
		page?: string | number;
		keywords?: string;
		customerId?: string | number;
	}) {
		const url = new URL(
			`${INTEGRATOR_API}/v1/accurate/customer/${customerId}/sale-orders`
		);
		const params = url.searchParams;
		const accurateCreds = getAccurateCreds();
		params.append("accessToken", accurateCreds?.accessToken as string);
		params.append("sessionId", accurateCreds?.session as string);
		params.append("host", accurateCreds?.host as string);
		if (keywords) {
			params.append("keywords", keywords as string);
		}
		if (page) {
			params.append("page", page as string);
		}
		const response = await instance.get(url.toString());

		return response.data;
	}
	static async updateDeliveryOrder({
		doId,
		startLoadingTime,
		endLoadingTime,
		branchId,
		saleOrderItemId,
		quantity
	}: {
		doId?: string;
		startLoadingTime?: string;
		endLoadingTime?: string;
		branchId?: string;
		saleOrderItemId?: unknown;
		quantity?: unknown;
	}) {
		const url = new URL(`${INTEGRATOR_API}/v1/accurate/delivery-order`);
		const accurateCreds = getAccurateCreds();
		const params = url.searchParams;
		params.append("accessToken", accurateCreds?.accessToken as string);
		params.append("sessionId", accurateCreds?.session as string);
		params.append("host", accurateCreds?.host as string);
		const response = await instance.post(url.toString(), {
			doId,
			startLoadingTime,
			endLoadingTime,
			branchId,
			saleOrderItemId,
			quantity
		});

		return response.data;
	}
	static async getAccurateSalesOrderDetail({ id }: { id?: number }) {
		const url = new URL(`${INTEGRATOR_API}/v1/accurate/sale-order/${id}`);
		const params = url.searchParams;
		const accurateCreds = getAccurateCreds();
		params.append("accessToken", accurateCreds?.accessToken as string);
		params.append("sessionId", accurateCreds?.session as string);
		params.append("host", accurateCreds?.host as string);
		const response = await instance.get(url.toString());

		return response.data;
	}
	static async createDeliveryOrder({
		payload
	}: {
		payload: Partial<deliveryOrderArgAccurate>;
	}) {
		const url = new URL(`${INTEGRATOR_API}/v1/accurate/delivery-order`);
		const accurateCreds = getAccurateCreds();
		const params = url.searchParams;
		params.append("accessToken", accurateCreds?.accessToken as string);
		params.append("sessionId", accurateCreds?.session as string);
		params.append("host", accurateCreds?.host as string);
		const response = await instance.post(url.toString(), payload);

		return response.data;
	}

	static async linkDeliveryOrder(
		id: string,
		{
			payload
		}: {
			payload: Partial<deliveryOrderArgAccurate>;
		}
	) {
		const url = new URL(
			`${ORDER_API}/order/b/delivery-order/${id}/accurate`
		);

		const accurateCreds = getAccurateCreds();
		// const params = url.searchParams;
		// params.append("accessToken", accurateCreds?.accessToken as string);
		// params.append("sessionId", accurateCreds?.session as string);
		// params.append("host", accurateCreds?.host as string);

		const response = await instance({
			url: url.toString(),
			method: "PUT",
			data: payload,
			// headers: {
			// 	Authorization: `Bearer ${localStorage.getItem("AccessToken")}`,
			// 	"Content-Type": "application/json"
			// }
			params: {
				accessToken: accurateCreds?.accessToken as string,
				sessionId: accurateCreds?.session as string,
				host: accurateCreds?.host as string
			}
		});

		return response.data;
	}

	static async getAccurateBranch({
		page,
		keywords
	}: {
		page?: string | number;
		keywords?: string;
	}) {
		const url = new URL(`${INTEGRATOR_API}/v1/accurate/branch`);
		const params = url.searchParams;
		const accurateCreds = getAccurateCreds();
		params.append("accessToken", accurateCreds?.accessToken as string);
		params.append("sessionId", accurateCreds?.session as string);
		params.append("host", accurateCreds?.host as string);
		if (page) {
			params.append("page", page as string);
		}
		if (keywords) {
			params.append("keywords", keywords as string);
		}

		const response = await instance.get(url.toString());

		return response.data;
	}
	static async getAccurateBranchApi({
		page,
		keywords
	}: {
		page?: string | number;
		keywords?: string;
	}) {
		const url = new URL(`${INTEGRATOR_API}/v1/accurate/api/branch`);
		const params = url.searchParams;

		if (page) {
			params.append("page", page as string);
		}
		if (keywords) {
			params.append("keywords", keywords as string);
		}

		const response = await instance.get(url.toString());

		return response.data;
	}
	static async getAccuratePaymentTermApi({
		page,
		keywords
	}: {
		page?: string | number;
		keywords?: string;
	}) {
		const url = new URL(`${INTEGRATOR_API}/v1/accurate/api/payment-term`);
		const params = url.searchParams;

		if (page) {
			params.append("page", page as string);
		}
		if (keywords) {
			params.append("keywords", keywords as string);
		}

		const response = await instance.get(url.toString());

		return response.data;
	}
	static async getAccurateEmployeeApi({
		page,
		keywords
	}: {
		page?: string | number;
		keywords?: string;
	}) {
		const url = new URL(`${INTEGRATOR_API}/v1/accurate/api/salesman`);
		const params = url.searchParams;

		if (page) {
			params.append("page", page as string);
		}
		if (keywords) {
			params.append("keywords", keywords as string);
		}

		const response = await instance.get(url.toString());

		return response.data;
	}

	static async getAccurateCustomerApi({
		page,
		keywords,
		size
	}: {
		page?: string | number;
		keywords?: string;
		size?: number;
	}) {
		const url = new URL(`${INTEGRATOR_API}/v1/accurate/api/customer`);
		const params = url.searchParams;

		if (page) {
			params.append("page", page as string);
		}
		if (keywords) {
			params.append("keywords", keywords as string);
		}
		if (size) {
			params.append("size", size.toString());
		}

		const response = await instance.get(url.toString());

		return response.data;
	}

	static async getAccurateWarehouse({
		page,
		keywords
	}: {
		page?: string | number;
		keywords?: string;
	}) {
		const url = new URL(`${INTEGRATOR_API}/v1/accurate/warehouse`);
		const params = url.searchParams;
		const accurateCreds = getAccurateCreds();
		params.append("accessToken", accurateCreds?.accessToken as string);
		params.append("sessionId", accurateCreds?.session as string);
		params.append("host", accurateCreds?.host as string);
		if (page) {
			params.append("page", page as string);
		}
		if (keywords) {
			params.append("keywords", keywords as string);
		}

		const response = await instance.get(url.toString());

		return response.data;
	}

	static async getListPaymentTerms({ keywords }: { keywords?: string }) {
		const url = new URL(`${INTEGRATOR_API}/v1/accurate/api/payment-term`);
		const params = url.searchParams;

		if (keywords) {
			params.append("keywords", keywords as string);
		}

		const response = await instance.get(url.toString());

		return response.data;
	}

	static async getBranch({ keywords }: { keywords?: string }) {
		const url = new URL(`${INTEGRATOR_API}/v1/accurate/api/branch`);
		const params = url.searchParams;

		if (keywords) {
			params.append("keywords", keywords as string);
		}

		const response = await instance.get(url.toString());

		return response.data;
	}

	static async getListCustomerAccurate({
		keywords,
		page
	}: {
		keywords?: string;
		page?: string | number;
	}) {
		const url = new URL(`${INTEGRATOR_API}/v1/accurate/api/customer`);
		const params = url.searchParams;

		if (keywords) {
			params.append("keywords", keywords as string);
		}

		if (page) {
			params.append("page", page as string);
		}

		const response = await instance.get(url.toString());

		return response.data;
	}

	static async getCustomerAccurateDetail({ id }: { id: number }) {
		const url = new URL(`${INTEGRATOR_API}/v1/accurate/api/customer/${id}`);

		const response = await instance.get(url.toString());

		return response.data;
	}
}

class HubService {
	static async getAllTechnicians() {
		const url = new URL(`${QC_API}/v1/bod-user/technician`);

		const response = await instance({
			method: "GET",
			url: url.toString(),
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});

		return response.data;
	}
	static async getSKUSpecification() {
		const url = new URL(`${CUSTOMER_API}/hub-qc/v1/sku/specification`);
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});

		return response.data;
	}

	static async getSkuOrder() {
		const url = new URL(`${CUSTOMER_API}/hub-qc/v1/sku/order`);
		const response = await instance.get(url.toString(), {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});

		return response.data;
	}

	static async getSku({ keywords }: { keywords?: string }) {
		const url = new URL(`${CUSTOMER_API}/hub-qc/v1/sku`);
		const response = await instance.get(url.toString(), {
			params: {
				subCode: keywords,
				page: 1,
				size: 100
			},
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});

		return response.data;
	}

	static async pdfGenQuotation(id: string) {
		const url = new URL(
			`${CUSTOMER_API}/hub-integrator/v1/pdf-gen/quotation`
		);
		// const response = await instance.post(url.toString(), {
		// 	headers: {
		// 		Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
		// 	},
		// 	data: {
		// 		id
		// 	}
		// });
		const response = await instance({
			method: "POST",
			url: url.toString(),
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			},
			data: {
				id
			}
		});

		return response.data;
	}
	static async pdfGenSaleOrder(id: string) {
		const url = new URL(
			`${CUSTOMER_API}/hub-integrator/v1/pdf-gen/sale-order`
		);
		const response = await instance({
			method: "POST",
			url: url.toString(),
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			},
			data: {
				id
			}
		});

		return response.data;
	}

	static async getCustomersAccurate(query?: {
		page?: number;
		size?: number;
		keywords?: string;
	}) {
		const url = new URL(
			`${CUSTOMER_API}/hub-integrator/v1/accurate/api/customer`
		);
		const response = await instance({
			method: "GET",
			url: url.toString(),
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			},
			params: query
		});

		return response.data;
	}

	static async getDetailCustomerAccurate(id: string) {
		const url = new URL(
			`${CUSTOMER_API}/hub-integrator/v1/accurate/api/customer/${id}`
		);
		const response = await instance({
			method: "GET",
			url: url.toString(),
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			}
		});

		return response.data;
	}

	static async getBranchList(query?: {
		page?: number;
		size?: number;
		keywords?: string;
	}) {
		const url = new URL(
			`${CUSTOMER_API}/hub-integrator/v1/accurate/api/branch`
		);
		const response = await instance({
			method: "GET",
			url: url.toString(),
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			},
			params: query
		});

		return response.data;
	}
	static async createDownPayment(data: unknown) {
		const url = new URL(
			`${CUSTOMER_API}/hub-integrator/v1/accurate/api/down-payment`
		);
		const response = await instance({
			method: "POST",
			url: url.toString(),
			headers: {
				Authorization: `Bearer ${localStorage.getItem("AccessToken")}`
			},
			data
		});

		return response.data;
	}
}

export {
	InventoryService,
	OrderService,
	CommonService,
	ProductivityService,
	FinanceService,
	GpskuService,
	DashboardService,
	CustomerService,
	AccurateService,
	HubService
};
